.App {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    width: 100%;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
  }



*{font-family:Poppins,sans-serif;}

body {
    overflow-x: hidden;
}
.first-accent {
    position: absolute;
    right: 4vw;
    top: 0;
    z-index: -10;
    width: 40vw;
}

.navbar-container {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 75vw;
    justify-content: space-between;
    filter: drop-shadow(0px 0px 5px #999);
    align-items: center;
    background-color: white;
    border-radius: 15px;
    padding: 0.7% 0.7%;
    box-sizing: border-box;
    z-index: 10;
}

.navbar-logo {
    width: 15vw;
}

.navbar-a-container {
    font-weight: 600;
    font-size: 0.85vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
    justify-content: space-between;
}

.navbar-socials-container {
    display: flex;
    flex-direction: row;
    border-left: #999 1px solid;
    padding: 0 5%;
    box-sizing: border-box;
    justify-content: space-between;
}

.navbar-socials-container button {
    border: none;
    background-color: transparent;
}
.navbar-a-container a {
    position: relative;
    text-decoration: none;
    padding-bottom: 4px;
    transition: border-bottom-color 0.3s;
}

.navbar-a-container a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s;
}

.navbar-a-container a:hover {
    border-bottom-color: #000;
}

.navbar-a-container a:hover::after {
    background-color: #000;
}
  

.landing-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.landing-contents {
    position: absolute;
    left: 12vw;
    top: 30vh;
}

.landing-contents h1 {
    color: #3D3E4B;
    font-size: 5vw;
    line-height: 7vw;
    width: 45vw;
    margin: 0;
    font-weight: 700;
}

.landing-contents h2 {
    color: #656975;
    font-weight: 500;
    font-size: 1.3vw;
}

.landing-contents button {
    border: none;
    outline: none;
    padding: 2vh 2.5vw;
    background-color: #19ab6e;
    border-radius: 10px;
    color: #fff;
    font-family:Poppins,sans-serif;
    margin-top: 2vh;
}

.landing-contents span {
    background-color: #FFD43D;
    padding: 8px;
    border-radius: 20px;
    box-sizing: border-box;
}

.green-background {
    z-index: -9;
    position: absolute;
    top: 60vh;
    left: 0;
    width: 100vw;
}

.test {
    color: black;
}

.OS {
    color: white;
    font-weight: 500  ;
    margin-bottom: 5vh;
}

.arrow-button {
    background-color: transparent;
    border: none;
    transition: transform 0.3s;
    scale: 2;
}

.arrow-button:hover {
    transform: scale(1.5);
}

.arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
  
  


.os-services-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60vw;
}

.services-container {
    padding-top: 30px;
    position: absolute;
    background-image: url(../components/green-bckg.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    top: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20vh;
}

.SMM-container {
    position: relative;
    width: 50vw;
    height: 50vh;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 5%;
    box-sizing: border-box;
    justify-content: space-between;
}

.SMM-headers-container {
    width: 80%;
}


.SMM-headers-container h1 {
    color: #3d3e4b;
    font-size: 2vw;
}

.SMM-headers-container h3 {
    color: #2f3041;
    font-weight: 500;
    font-size: 1.35vw;
    width: 95%;
    opacity: 0.8;
}

.our-clients-container {
    width: 100vw;
    height: 50vh;
    position: absolute;
    top: 190vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.client-container { 
    width: 18vw;
    height: 20vh;
    filter: drop-shadow(0px 0px 5px #999);
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
}

.clients-container { 
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-around;
}

.client-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50%;
    justify-content: space-between;
    
}

.client-numbers {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.client-container h1 {
    font-size: 1vw;
    margin: 0;
    color: #fff;
    text-shadow: 0 0 2px #000; 

}

.client-container h2 {
    font-size: 0.8vw;
    margin: 0;
    opacity: 0.8;
    font-weight: 500;
    color: #fff;
    text-shadow: 0 0 2px #000; 
}

.client-numbers h1 {
    font-size: 0.7vw;
    color: #fff;
    text-shadow: 0 0 2px #000; 
}

.client-numbers h2 {
    font-weight: 0.5vw;
    color: #ffff;
    text-shadow: 0 0 7px #000; 
}

.client-numberss {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.client-row button {
    outline: none;
    border: none;
    border-radius: 5px;
    background-color: #2F3041;
    color: white;
    width: 100%;
}

.TRSE {
    background-image: url(../Assets/TRSE.png);
    background-size: cover;
}

.BG {
    background-image: url(../Assets/BG.png);
    background-size: cover;
}

.PCS {
    background-image: url(../Assets/PCS.png);
    background-size: cover;
}

.NS {
    background-image: url(../Assets/NS.png);
    background-size: cover;
}

.TSG {
    background-image: url(../Assets/TSG.png);
    background-size: cover;
}

.footer-container {
    width: 100vw;
    position: absolute;
    top: 240vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-contact-container {
    background-color: #19ab6e;
    width: 100vw;
    height: 90vh;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-contact-headers {
    width: 30%;
}

.footer-contact-headers h1{
    width: 85%;
    margin: 0;
    color: white;
    font-size: 3vw;
}

.footer-contact-headers h2 {
    color: #F7F7F7;
    font-weight: 400;
}

.footer-contact-fields {
    display: flex;
    flex-direction: column;
}

.footer-contact-fields {
    height: 42vh;
    justify-content: space-between;
}

.input1 {
    width: 30vw;
    height: 6vh;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
}

.input2 {
    width: 30vw;
    height: 22vh;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 10px;
    box-sizing: border-box;

}

.footer-contact-fields button {
    width: 10vw;
    background-color: #2F3041;
    color: white;
    padding: 1vh 1vw;
    outline: none;
    border: none;
    border-radius: 5px;
}

.our-clients-container h1 {
    margin: 0;
}

.star-container {
    padding: 3vh 0;
}

.star {
    padding: 0 0.3vw;
}


.globe {
    position: absolute;
    left: 0;
    z-index: 0;
}

.footer-footer-container {
    display: flex;
    flex-direction: row;
    padding: 5vh 8vw;
    margin-top: 20vh;
    justify-content: center;
    align-items: center;
}

.footer-description h1 {
    font-weight: 400;
    font-size: 1vw;
    width: 40%;
}

.footer-nav {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
}

.footer-image {
    width: 18vw;
}

.footer-images {
    padding: 0 1vw;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 75vh;
    width: 105vw;
}